import { Auctions } from "@liqnft/candy-shop";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { candyShop } from "../utils/candy-shop";
import styled from "styled-components";

const AuctionsView: React.FC = () => {
  const wallet = useAnchorWallet();

  return (
    <DesContainer>
      <img width={155} src="https://cdn.discordapp.com/attachments/459557016042471454/1043673203844841552/ezgif.com-gif-maker.gif" style={{ marginTop: 40, marginBottom: 25  }}></img>
      <h3 style={{ marginTop: 10, marginBottom: 35  }}><span style={{backgroundColor: "rgb(15,11,7, 0.5)", padding: 10, borderRadius: "10px 10px 10px 10px"}}>Active Auctions.</span></h3>

      {/* <p style={{ marginBottom: 40 }}>Note: Shop owners can create auctions in the <a href="https://candy.liqnft.com/shop/auctions" target="_blank" rel="noreferrer noopener">admin panel</a>.</p> */}
      <div style={{ marginTop: 80 }} >
      <Auctions  candyShop={candyShop} wallet={wallet} walletConnectComponent={<WalletMultiButton />} />
      </div>
    </DesContainer>
  );
};

export default AuctionsView;
const DesContainer = styled.div`
  width: 100%;

  p > a {
    color: #fff;
    text-decoration: underline;
  }
`;
